@font-face {
  font-family: "Druk";
  font-weight: 700;
  font-style: normal;
  src: url("Druk-Bold-Web.woff2") format("woff2"), url("Druk-Bold-Web.woff") format("woff"), url("Druk-Bold-Web.ttf") format("ttf");
}
@font-face {
  font-family: "Druk";
  font-weight: 700;
  font-style: italic;
  src: url("Druk-Bold-Italic-Web.woff2") format("woff2"), url("Druk-Bold-Italic-Web.woff") format("woff"), url("Druk-Bold-Italic-Web.ttf") format("ttf");
}
@font-face {
  font-family: "Druk";
  font-weight: 900;
  font-style: normal;
  src: url("Druk-Heavy-Web.woff2") format("woff2"), url("Druk-Heavy-Web.woff") format("woff"), url("Druk-Heavy-Web.ttf") format("ttf");
}
@font-face {
  font-family: "Druk";
  font-weight: 900;
  font-style: italic;
  src: url("Druk-Heavy-Italic-Web.woff2") format("woff2"), url("Druk-Heavy-Italic-Web.woff") format("woff"), url("Druk-Heavy-Italic-Web.ttf") format("ttf");
}
@font-face {
  font-family: "Druk";
  font-weight: 500;
  font-style: normal;
  src: url("Druk-Medium-Web.woff2") format("woff2"), url("Druk-Medium-Web.woff") format("woff"), url("Druk-Medium-Web.ttf") format("ttf");
}
@font-face {
  font-family: "Druk";
  font-weight: 500;
  font-style: italic;
  src: url("Druk-Medium-Italic-Web.woff2") format("woff2"), url("Druk-Medium-Italic-Web.woff") format("woff"), url("Druk-Medium-Italic-Web.ttf") format("ttf");
}
@font-face {
  font-family: "Druk";
  font-weight: 600;
  font-style: normal;
  src: url("Druk-Super-Web.woff2") format("woff2"), url("Druk-Super-Web.ttf") format("ttf");
}
@font-face {
  font-family: "Druk";
  font-weight: 600;
  font-style: italic;
  src: url("Druk-Super-Italic-Web.woff2") format("woff2"), url("Druk-Super-Italic-Web.woff") format("woff"), url("Druk-Super-Italic-Web.ttf") format("ttf");
}